import { InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PageHeader from '../../../components/common/PageHeader';
import { Mode } from '@/_models/User';

import { getPageTour } from '@/services/tour';
import LoadingProgress from '@/components/common/LoadingProgress';

const DynamicComponent3WithNoSSR = dynamic(() => import('../../../components/GameTourStateWrap'), {
  loading: () => <LoadingProgress />,
  ssr: false,
});

export const getServerSideProps = async (context: { query: { slug: any; pmeToken: any }; req: any }) => {
  const { slug } = context.query;
  const s = slug[1];
  const tourData = await getPageTour({
    slug: s,
    mode: Mode.Game,
    origin: context.req.headers.host,
    pmeToken: context.query.pmeToken,
  });
  const data = JSON.stringify({ ...tourData, title: '3D Experience' });

  return {
    props: {
      data,
    },
  };
};

const getParams = (slug: string | string[]) => {
  let matterId;
  let gameId;
  let endgameData;
  let videoType;
  let rule;
  let room;
  let renderType = 2;
  // renderType
  // 0 : /experience/:gameId/:mattterId
  // 1 : /experience/:gameId/:mattterId/:endgameData ??
  // 2 : /experience/:gameId/:mattterId/:videoType/:rule/:room

  if (slug.length === 2) {
    gameId = slug[0];
    matterId = slug[1];
    renderType = 0;
  } else if (slug.length === 3) {
    gameId = slug[0];
    matterId = slug[1];
    endgameData = slug[2];
    renderType = 1;
  } else if (slug.length === 5) {
    gameId = slug[0];
    matterId = slug[1];
    videoType = slug[2];
    rule = slug[3];
    room = slug[4];
    renderType = 2;
    // eslint-disable-next-line no-console
  } else console.log('Wrong url, ask Boaz how to behave');
  return {
    gameId,
    matterId,
    endgameData,
    videoType,
    rule,
    room,
    renderType,
  };
};

const IndexPage = ({ data }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { title, description, tourImage, website, fbPixel, fbAppId, faviconUrl, htmlCode, tourData, tags, matterTags, charset } = JSON.parse(data);
  // renderType
  // 0 : /experience/:gameId/:mattterId/

  const router = useRouter();
  const { slug } = router.query;
  if (!slug) return <LoadingProgress />;

  const { gameId, matterId, endgameData, videoType, rule, room, renderType } = getParams(router.query.slug);

  return (
    <PageHeader
      title={title}
      description={description}
      slug={matterId}
      tourImage={tourImage}
      website={website}
      fbPixel={fbPixel}
      fbAppId={fbAppId}
      faviconUrl={faviconUrl}
      htmlCode={htmlCode}
      type={`experience/${gameId}`}
      charset={charset}
    >
      <DynamicComponent3WithNoSSR
        tourData={tourData}
        tags={tags}
        renderType={renderType}
        matterTags={matterTags}
        tourImage={tourImage}
        matterId={matterId}
        gameId={gameId}
        endgameData={endgameData}
        videoType={videoType}
        rule={rule}
        room={room}
        reactAws={process.env.REACT_APP_AWS}
        reactAppDomain={process.env.REACT_APP_DOMAIN}
        reactAppMatterPort={process.env.REACT_APP_MATTERPORT}
        reactPaypalClient={process.env.REACT_APP_PAYPAL_CLIENT}
      />
    </PageHeader>
  );
};

export default IndexPage;
